$(document).on('turbo:load', function() {

 $( "#sortable" ).sortable();

  $('tr.clickable').each(function(i,e) {
    $(e).children('td').not('.nolink').click(function() {
      //here we are working on a td element, that's why we need
      //to refer to its parent (tr) in order to find the a element
      var href = $(this).closest("tr").find("a").attr("href");
      if(href) {
        window.location = href;
      }              
    });
  });

  $('tr.selectable').each(function(i,e) {
    $(e).children('td').not('.nolink').click(function() {
      //here we are working on a td element, that's why we need
      //to refer to its parent (tr) in order to find the a element
      var check_box = $(this).closest("tr").find("input")[0];

      if (check_box.checked) {
        check_box.checked = false;
      } else {
        check_box.checked = true;
      }           
    });
  });

  function sortableList(list_selector, list_item_class, direction){
    $(list_selector).sortable({
      axis: direction,
      dropOnEmpty: false,
      items: list_item_class,
      opacity: 0.4,
      scroll: true,
      update: function(){
        $.ajax({
          type: 'patch',
          data: $(this).sortable('serialize'),
          dataType: 'script',
          complete: function(request){
            $(list_selector).effect( "highlight", {color:"#E1F3E1"}, 1500 );
          },
          url: $(this).attr('data-sort-path'),
          error:function (xhr, msg, error) {
            alert(msg + ": " + error);
          }
        })
      }
    });
  }

  sortableList('ul.sortable-product_groups', 'li', 'y');

});
