// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("@rails/activestorage").start()
import { Turbo } from "@hotwired/turbo-rails"
require("channels")
require("jquery")
require("packs/foundation.js")
require("packs/lightbox.min.js")

import 'cocoon-js';

import $ from 'jquery';
global.$ = $
global.jQuery = $
import 'packs/custom.ui.js';
import '../src/jquery-ui';

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

import "controllers"
